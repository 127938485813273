<template>
    <div :is="layout" style="position: relative">
        <div v-show="box_info_show" class="box_info">
            <p>
                <span>{{$t('flight_information.sector_name')}}:</span>
                <span>{{sector_info.name}}</span>
            </p>
            <p>
                <span>{{$t('flight_information.load')}}:</span>
                <span>{{sector_info.load}}</span>
            </p>
            <p>
                <span>{{$t('flight_information.remark')}}:</span>
                <span>{{sector_info.desction}}</span>
            </p>
        </div>
        <div id="cesiumContainer"></div>
        <div style="position: absolute; top: 100px; left: 30px">
            <v-btn fab style="margin-right: 10px" color="primary" @click="step = 0">{{$t('flight_information.first_step')}}</v-btn>
            <v-btn fab style="margin-right: 10px" color="primary" @click="step = 1">{{$t('flight_information.second_step')}}</v-btn>
            <v-btn fab style="margin-right: 10px" color="primary" @click="step = 2">{{$t('flight_information.third_step')}}</v-btn>
        </div>
    </div>
</template>
<script>
    import {get_3D_data, get_2D_data} from "../../../api/flight_information_preview";
    // 航迹
    import flight from '../flightInformation'
    let Cesium;
    export default {
        name: 'flightInformation',
        data: () => ({
            step: null,
            layout: null,
            viewer: null,
            sector_info: {
                name: '加载中...',
                load: '加载中...',
                desction: '加载中...'
            },
            box_info_show: false
        }),
        methods: {
            init() {
                Cesium = this.Cesium
                this.viewer = new Cesium.Viewer('cesiumContainer', {
                    // scene3DOnly: true
                })
            },
            _0() {
                //获取3D数据
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const closeLoading = this.$loading()
                this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
                get_3D_data({pagesize: 9999999, flight_information_id: this.$route.params.id}).then(res => {
                    const data_list = res.results;
                    // 因为比较会造成浏览器内存不足,导致浏览器奔溃所以不建议使用entity进行操作
                    // data_list.forEach(x => {
                    //     const entities = new Cesium.Entity({
                    //         position: Cesium.Cartesian3.fromDegrees(-90 + parseFloat(x.x) / 1000, 40 + parseFloat(x.y) / 1000, parseFloat(x.z) * 8000),
                    //         box: {
                    //             show: true,
                    //             outline: false,
                    //             outlineColor: Cesium.Color.RED,
                    //             dimensions: new Cesium.Cartesian3(1100, 1100, 1100),
                    //             material: Cesium.Color.BLUE.withAlpha(.01),
                    //             fill: true
                    //         }
                    //     })
                    //     this.customDataSource.entities.add(entities)
                    // })
                    // this.viewer.dataSources.add(this.customDataSource)
                    // 在这里使用primitive进行对图元操作
                    const boxInstanceList = []
                    data_list.forEach(x => {
                        const box = Cesium.BoxGeometry.fromDimensions({
                            vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
                            dimensions: new Cesium.Cartesian3(1100, 1100, 1100)
                        })
                        let boxgeometry = Cesium.BoxGeometry.createGeometry(box);

                        boxInstanceList.push(new Cesium.GeometryInstance({
                            geometry: boxgeometry,
                            modelMatrix: Cesium.Matrix4.multiplyByTranslation(Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(-90 + parseFloat(x.x) / 772.7, 40 + parseFloat(x.y) / 1010)), new Cesium.Cartesian3(0, 0, parseFloat(x.z) * 7300), new Cesium.Matrix4()),
                            attributes: {
                                color: new Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromRandom().withAlpha(.02))
                            },
                            id: 'top'
                        }))
                    })
                    const primitive =  new Cesium.Primitive({
                        geometryInstances: boxInstanceList,
                        asynchronous: false,
                        appearance : new Cesium.PerInstanceColorAppearance({
                            translucent : true,
                            closed : true
                        })
                    })

                    this.viewer.scene.primitives.add(primitive, 0)
                    this.viewer.camera.flyTo({
                        destination : Cesium.Cartesian3.fromDegrees(-90, 40,95010),
                        orientation : {
                            heading : Cesium.Math.toRadians(0.0),
                            pitch : Cesium.Math.toRadians(-25.0),
                            roll : 0.0
                        }
                    });

                }).finally(() => {
                    closeLoading()
                })
            },


            _1() {
                // 这里获取2D数据

                const closeLoading = this.$loading()
                get_2D_data({pagesize: 9999999, flight_information_id: this.$route.params.id}).then(res => {

                    const judge_primitive = this.viewer.scene.primitives.get(0)
                    if (judge_primitive) {
                        judge_primitive.show = false
                    }
                    this.viewer.dataSources && this.viewer.dataSources.removeAll()
                    const customDataSource = new this.Cesium.CustomDataSource('1customDataSource')

                    this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
                    const data_list = res.results
                    data_list.forEach(x => {
                        const entities = new Cesium.Entity({
                            position: Cesium.Cartesian3.fromDegrees(-90 + parseFloat(x.x) / 772.7, 40 + parseFloat(x.y) / 1010, 0),
                            plane: {
                                show: true,
                                plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0),
                                dimensions: new Cesium.Cartesian2(1100, 1100),
                                material: Cesium.Color.BLUE,
                                outline: true,
                                outlineColor: Cesium.Color.WHITE
                            }
                        })
                        customDataSource.entities.add(entities)
                    })
                    this.viewer.dataSources.add(customDataSource)
                    this.viewer.zoomTo(customDataSource)
                }).finally(() => {
                    closeLoading()
                })
            },



            _2() {
                this.viewer.dataSources && this.viewer.dataSources.removeAll()
                const judge_primitive = this.viewer.scene.primitives.get(0)
                if (judge_primitive) {
                    judge_primitive.show = false
                }
                const customDataSource = new Cesium.CustomDataSource('2customDataSource')

                this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
                // 减少渲染次数
                flight.flightInformation.forEach((item) => {
                    const entities = new Cesium.Entity()
                    entities.polygon = new Object()
                    entities.polygon.show = true
                    entities.polygon.closeBottom = false
                    entities.polygon.closeTop = false
                    entities.polygon.hierarchy = {
                        positions: Cesium.Cartesian3.fromDegreesArray(
                            item.flight_information,
                        ),
                    }
                    entities.polygon.material = this.Cesium.Color.fromRandom({ alpha: 0.4 })
                    entities.polygon.height = 100000
                    entities.polygon.extrudedHeight = 0
                    entities.polygon.outline = true
                    entities.polygon.outlineColor = this.Cesium.Color.WHITE.withAlpha(0.3)

                    const entities_material = new Cesium.Entity()
                    entities_material.polygon = new Object()
                    entities_material.meta = {
                        name: '扇区名称',
                        load: Math.random(),
                        desction: '备注信息'
                    }
                    entities_material.polygon.show = true
                    entities_material.polygon.closeBottom = false
                    entities_material.polygon.outline = false
                    // entities_material.polygon.outlineColor = this.Cesium.Color.WHITE.withAlpha(0.3)
                    entities_material.polygon.hierarchy = {
                        positions: Cesium.Cartesian3.fromDegreesArray(
                            item.flight_information,
                        ),
                    }
                    // entities_material.polygon.material =  new Cesium.GridMaterialProperty();
                    entities_material.polygon.material = Cesium.Color.RED.withAlpha(0.01)
                    entities_material.polygon.heightReference = Cesium.HeightReference.RELATIVE_TO_GROUND
                    entities_material.polygon.height = 100000
                    entities_material.polygon.extrudedHeight = undefined


                    // 添加鼠标事件
                    const handle = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas)
                    handle.setInputAction(event => {
                        const sector_info = this.viewer.scene.pick(event.position)
                        if (!Cesium.defined(sector_info)) {
                            this.box_info_show = false
                            return
                        }
                        this.sector_info = sector_info.id.meta
                        this.box_info_show = true
                    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)


                    customDataSource.entities.add(entities)
                    customDataSource.entities.add(entities_material)
                });

                this.viewer.dataSources.add(customDataSource)

                this.viewer.flyTo(customDataSource)
            }
        },
        watch: {
            step(v) {
                this[`_${v}`]()
            }
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            this.$nextTick(() => {
                this.init()
                this.step = 0

            })
        }
    }
</script>
<style lang="stylus" scoped>

    .box_info
        width 300px
        background rgba(255, 255, 255, .8)
        position absolute
        top 80px
        left 10px
        z-index 1
        border-radius 3px
        font-size 13px
        padding: 20px

</style>
